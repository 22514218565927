<template>
  <div class="organizations">
    <form class="form-horizontal" v-on:submit.prevent="saveChanges">
      <div class="form-group">
        <label for="name">Name:</label>
        <input
          type="text"
          name="name"
          v-model="organization.name"
          placeholder="Name of Organization"
          class="form-control"
        />
      </div>

      <div class="form-group">
        <label for="address">Address</label>
        <textarea
          class="form-control"
          rows="4"
          v-model="organization.address"
        ></textarea>
      </div>

      <div class="form-group">
        <label for="country">Country:</label>
        <select class="form-control" name="country" v-model="organization.country">
          <option
            v-for="country in countries"
            v-bind:value="country.value"
            v-bind:key="country.value"
          >
            {{ country.title }}
          </option>
        </select>
      </div>

      <div class="form-group">
        <label for="phone">Phone:</label>
        <input
          type="text"
          name="phone"
          v-model="organization.phone"
          placeholder="Phone"
          class="form-control"
        />
      </div>

      <div class="form-group">
        <label for="seats">Seats:</label>
        <input
          type="text"
          name="seats"
          v-model="organization.seats"
          placeholder="Seats"
          class="form-control"
        />
      </div>

      <div class="form-group">
        <label for="cec_count">CEC Count:</label>
        <input
          type="text"
          name="cec_count"
          v-model="organization.cec_count"
          placeholder="CEC Count"
          class="form-control"
        />
      </div>

      <div class="form-group signup-carousel-buttons">
        <button class="btn btn-primary">Save</button>
      </div>
    </form>

    <table class="table">
      <tr>
        <th>ID</th>
        <th>Email</th>
        <th>Fullname</th>
        <th>Owner</th>
        <th>&nbsp;</th>
      </tr>
      <tr v-for="user in organization.users" v-bind:key="user.user_id">
        <td>{{ user.user_id }}</td>
        <td>{{ user.email }}</td>
        <td>{{ user.fullname }}</td>
        <td>{{ user.owner }}</td>
        <td><router-link :to="{ path: '/users/' + user.user_id }"
            ><button class="btn btn-success btn-xs">
              View User Account
            </button></router-link
          ></td>
      </tr>
    </table>
  </div>
</template>

<style></style>

<script>
import OrganizationService from "../../services/OrganizationService";
import countries from "../../data/countries.json";

export default {
  data: function () {
    return {
      organization: {
        name: "",
        address: "",
        country: "United States",
        phone: "",
        seats: 0,
        cec_count: 0,
        users: []
      },
      countries: countries
    };
  },

  created: function () {
    if (!isNaN(this.$route.params.id)) {
      OrganizationService.findById(this.$route.params.id).then((response) => {
        let data = response.data;

        this.organization.name = data.name;
        this.organization.address = data.address;
        this.organization.country = data.country;
        this.organization.phone = data.phone;
        this.organization.seats = parseInt(data.seats);
        this.organization.cec_count = parseInt(data.cec_count);
        this.organization.users = data.users;
      });
    }
  },

  methods: {
    saveChanges: function () {
      const cecCount = parseInt(this.organization.cec_count);
      let organizationRow = {
        name: this.organization.name || "",
        address: this.organization.address || "",
        country: this.organization.country || "",
        phone: this.organization.phone || "",
        seats: parseInt(this.organization.seats) || "",
        cec_count:  Number.isNaN(cecCount) ? 0 : cecCount
      };

      if (!isNaN(this.$route.params.id)) {
        OrganizationService.update(this.$route.params.id, organizationRow).then(
          (response) => {
            alert("Organization Updated");
          }
        );
      } else if (this.$route.params.id == "add") {
        OrganizationService.create(organizationRow).then((response) => {
          alert("Organization created.");
        });
      }
    },
  },
};
</script>
